import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useGlobalContext } from '@context/GlobalContexts'
import { useSession } from '@context/SessionManagementContext'
import runtimeConfig from '@lib/RuntimeConfig'
import { StatsigClient } from '@statsig/js-client'
import { SessionReplay } from '@statsig/session-replay'

interface SessionRecordingContextValue {
  forceStartRecording: () => void
  startSampledRecording: (sampleRate: number) => void
}

const SessionRecordingContext = createContext<SessionRecordingContextValue | null>(null)

interface SessionRecordingProviderProps {
  children: ReactNode
}

export function SessionRecordingProvider({ children }: SessionRecordingProviderProps) {
  const { currentUser } = useCurrentUserContext()
  const { session } = useSession()
  const { isEnabled } = runtimeConfig().publicRuntimeConfig.analytics.statsig
  const { browserInfo } = useGlobalContext()

  const disabled = !isEnabled || browserInfo.browser.bot

  const statsigClient = useMemo(() => {
    const client = new StatsigClient(runtimeConfig().publicRuntimeConfig.analytics.statsig.clientKey, {
      userID: currentUser?.user_id?.toString() || session?.id || 'anonymous',
    })

    return client
  }, [currentUser?.user_id, session?.id])

  const sessionReplay = useMemo(() => {
    if (disabled) return null
    return new SessionReplay(statsigClient)
  }, [statsigClient, disabled])

  const forceStartRecording = useCallback(() => {
    if (disabled) return
    sessionReplay?.forceStartRecording()
  }, [sessionReplay, disabled])

  const startSampledRecording = useCallback(
    (sampleRate: number) => {
      if (disabled) return
      if (Math.random() < sampleRate) {
        sessionReplay?.forceStartRecording()
      }
    },
    [sessionReplay, disabled]
  )

  const value = useMemo(
    () => ({
      forceStartRecording,
      startSampledRecording,
    }),
    [forceStartRecording, startSampledRecording]
  )

  return <SessionRecordingContext.Provider value={value}>{children}</SessionRecordingContext.Provider>
}

export function useRecordSession() {
  const context = useContext(SessionRecordingContext)
  if (!context) {
    throw new Error('useRecordSession must be used within a SessionRecordingProvider')
  }
  return context
}
