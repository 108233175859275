import { InternalAxiosRequestConfig, Method } from 'axios'
import cloneDeep from 'lodash.clonedeep'
import { useCallback, useEffect } from 'react'

import { overrideCommandContextInterceptor } from '@lib/commands/overrideCommandContextInterceptor'
import { resolveDeviceUUID } from '@lib/commands/resolveDeviceUUID'
import { commands } from '@lib/messages/protobuf'
import runtimeConfig from '@lib/RuntimeConfig'
import { useCommandContext } from './CommandContext'
import { useGlobalContext } from './GlobalContexts'

import CommandContext = commands.CommandContext

const HTTP_METHODS_REQUIRING_CONTEXT = new Set<Method>(['DELETE', 'POST', 'PUT'])

export const CommandContextInterceptor = () => {
  const { commandContext } = useCommandContext()
  const { browserInfo } = useGlobalContext()
  const buildID = runtimeConfig().publicRuntimeConfig.buildID

  const setCommandContextInterceptor = useCallback(
    (
      config: InternalAxiosRequestConfig<{ context?: CommandContext }>
    ): InternalAxiosRequestConfig<{ context?: CommandContext }> => {
      if (!config.baseURL?.includes('mix.com')) return config
      config.headers.set('mix-device-id', resolveDeviceUUID())
      if (browserInfo.isDesktop) {
        config.headers.set('x-mix-client', `com.mix.web/${buildID}`)
      } else {
        config.headers.set('x-mix-client', `com.mix.web.mobile/${buildID}`)
      }

      if (!HTTP_METHODS_REQUIRING_CONTEXT.has((config.method?.toUpperCase() ?? 'GET') as Method)) return config

      config.data ??= {}
      const commandContextCopy = cloneDeep(commandContext)
      commandContextCopy.eventTimestamp = Date.now()
      if (!config.data.context) config.data.context = commandContextCopy
      return config
    },
    [commandContext, browserInfo.isDesktop, buildID]
  )
  useEffect(() => {
    overrideCommandContextInterceptor(setCommandContextInterceptor)
  }, [setCommandContextInterceptor])

  return <></>
}
