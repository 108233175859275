import { APIRequest } from '@hooks/useRequest'
import { WEEK_TO_SECONDS } from '@lib/time-constants'
import { IntoNode } from '@models/IntoNode'
import { IntoUser } from '@models/IntoUser'
import RecommendResponse, { filterResourcesByType, filterResourcesByTypes } from '@models/RecommendResponse'
import IntoAPI, { IntoUserPaginatedRequest } from '@services/IntoAPI'
import { mixApi } from '../api/root'
import { setMediaTypes } from '../slices/appSlice'
import { setHasReachedEnd as setFeedHasReachedEnd, setIsChangingFeed } from '../slices/feedSlice'
import { setHasReachedEnd as setUserHasReachedEnd } from '../slices/userSlice'
import nodeApi from './nodeApi'

type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]

interface UserApiRequestBase {
  userId?: number
  username?: string
  cookie?: string | null
}

type UserApiRequest = RequireOnlyOne<UserApiRequestBase, 'userId' | 'username'>

interface UserFollowApiRequest {
  userId: number
  username: string
  cookie?: string | null
}

interface UserFollowingApiRequest {
  userId: number
  username: string
  cookie?: string | null
  page: number
}

const userApi = mixApi.injectEndpoints({
  endpoints: builder => ({
    getUserDetails: builder.query<IntoUser | undefined, UserApiRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: ({ username, userId, cookie }) => {
        let config = {} as APIRequest
        if (username) {
          config = IntoAPI.user.getDetails({ username })
        } else if (userId) {
          config = IntoAPI.user.getDetails({ user_id: userId })
        }
        if (cookie) {
          config = { ...config, headers: { ...config.headers, cookie } }
        }

        return {
          config,
        }
      },
      providesTags: (result, error, { userId }) => {
        const id = result ? result.user_id : userId
        return id ? [{ type: 'User', id }] : []
      },
      serializeQueryArgs: ({ queryArgs: { username, userId }, endpointName }) => {
        if (username) {
          return `${endpointName}({"username":"${username}"})}`
        }

        if (userId) {
          return `${endpointName}({"user_id":"${userId}"})}`
        }

        return `${endpointName}({})`
      },
    }),
    followUser: builder.mutation<IntoUser, UserFollowApiRequest>({
      query: args => {
        const { userId, cookie } = args
        let config = {} as APIRequest
        config = IntoAPI.user.follow({ user_id: userId })
        if (cookie) {
          config = { ...config, headers: { ...config.headers, cookie } }
        }
        return {
          config,
        }
      },
      onQueryStarted: ({ userId }, { dispatch, queryFulfilled }) => {
        dispatch(
          userApi.util.updateQueryData('getUserDetails', { userId }, draft => {
            if (draft) {
              draft.meta.isLiked = true
            }
          })
        )

        queryFulfilled.catch(() => {
          dispatch(
            userApi.util.updateQueryData('getUserDetails', { userId }, draft => {
              if (draft) {
                draft.meta.isLiked = false
              }
            })
          )
        })
      },
    }),
    unfollowUser: builder.mutation<IntoUser | undefined, UserFollowApiRequest>({
      query: args => {
        const { userId, cookie } = args
        let config = IntoAPI.user.unfollow({ user_id: userId })
        if (cookie) {
          config = { ...config, headers: { ...config.headers, cookie } }
        }
        return {
          config,
        }
      },
      onQueryStarted: ({ userId }, { dispatch, queryFulfilled }) => {
        dispatch(
          userApi.util.updateQueryData('getUserDetails', { userId }, draft => {
            if (draft) {
              draft.meta.isLiked = false
            }
          })
        )

        queryFulfilled.catch(() => {
          dispatch(
            userApi.util.updateQueryData('getUserDetails', { userId }, draft => {
              if (draft) {
                draft.meta.isLiked = true
              }
            })
          )
        })
      },
    }),
    getLikedUrls: builder.query<RecommendResponse, IntoUserPaginatedRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: ({ page, user_id: userId }) => {
        const config = { ...IntoAPI.user.getUrls({ user_id: userId, page }) }

        return {
          config,
        }
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          const { data } = response
          const { items, meta } = data
          const incomingMediaTypes = meta?.mediaTypes

          if (incomingMediaTypes && incomingMediaTypes?.length > 0) {
            dispatch(setMediaTypes(incomingMediaTypes))
          }

          const urls = filterResourcesByType(items, 'url')

          if (!urls?.length) {
            dispatch(setFeedHasReachedEnd(true))
          }
          dispatch(setIsChangingFeed(false))
        } catch (_err) {
          /* Do nothing */
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs: { user_id: userId } }) => {
        return `${endpointName}:${userId}`
      },

      merge(currentCache, newState) {
        const existingFeed = currentCache
        if (existingFeed && newState) {
          existingFeed.items = [...existingFeed.items, ...newState.items]
        } else {
          currentCache = newState
        }

        return currentCache
      },
      forceRefetch({ currentArg, previousArg }) {
        const shouldRefetch = currentArg?.page !== previousArg?.page
        return shouldRefetch
      },
    }),
    getPostedUrls: builder.query<RecommendResponse, IntoUserPaginatedRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: ({ page, user_id: userId }) => {
        const config = { ...IntoAPI.user.getPosts({ user_id: userId, page }) }

        return {
          config,
        }
      },
      async onQueryStarted({ page, user_id: userId }, { dispatch, queryFulfilled }) {
        if (page === 1) {
          dispatch(setIsChangingFeed(true))
          dispatch(setFeedHasReachedEnd(false))

          const userDetails = await dispatch(userApi.endpoints.getUserDetails.initiate({ userId })).unwrap()
          const username = userDetails?.username

          if (username) {
            dispatch(
              setUserHasReachedEnd({
                username,
                type: 'posts',
                hasReachedEnd: false,
              })
            )
          }
        }

        try {
          const response = await queryFulfilled
          const { data } = response
          const { items, meta } = data
          const incomingMediaTypes = meta?.mediaTypes

          if (incomingMediaTypes && incomingMediaTypes?.length > 0) {
            dispatch(setMediaTypes(incomingMediaTypes))
          }

          const urls = filterResourcesByType(items, 'url')

          if (!urls?.length) {
            dispatch(setFeedHasReachedEnd(true))

            const userDetails = await dispatch(userApi.endpoints.getUserDetails.initiate({ userId })).unwrap()
            const username = userDetails?.username

            if (username) {
              dispatch(
                setUserHasReachedEnd({
                  username,
                  type: 'posts',
                  hasReachedEnd: true,
                })
              )
            }
          }
          dispatch(setIsChangingFeed(false))
        } catch (_err) {
          dispatch(setIsChangingFeed(false))
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs: { user_id: userId } }) => {
        return `${endpointName}:${userId}`
      },

      merge(currentCache, newState, { arg: { page } }) {
        if (page === 1) {
          return newState
        }

        const existingFeed = currentCache
        if (existingFeed && newState) {
          existingFeed.items = [...existingFeed.items, ...newState.items]
        } else {
          return newState
        }

        return existingFeed
      },
      forceRefetch({ currentArg, previousArg }) {
        const shouldRefetch = currentArg?.page !== previousArg?.page || currentArg?.user_id !== previousArg?.user_id
        return shouldRefetch
      },
    }),
    getUserFollowing: builder.query<(IntoUser | IntoNode)[] | undefined, UserFollowingApiRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: args => {
        const { userId, cookie, page } = args
        let config = IntoAPI.user.getLikes({ page, user_id: userId, keyId: 'getUserLikes' })
        if (cookie) {
          config = { ...config, headers: { ...config.headers, cookie } }
        }
        return {
          config,
        }
      },
      serializeQueryArgs: ({ queryArgs: { username }, endpointName }) => {
        return `${endpointName}({"username":"${username}"})}`
      },
      merge: (existing, incoming) => {
        return [...(existing ? existing : []), ...(incoming ? incoming : [])]
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page && (currentArg?.page ?? 0) > (previousArg?.page ?? 0)
      },
      providesTags: (_result, _error, { username }) => {
        return [{ type: 'User', id: `${username}_following` }]
      },
      onQueryStarted: async ({ username }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          if (data) {
            const users = filterResourcesByType(data, 'user')
            const nodes = filterResourcesByType(data, 'node')

            users.forEach(user =>
              dispatch(userApi.util.upsertQueryData('getUserDetails', { userId: user.user_id }, user))
            )
            nodes.forEach(node => dispatch(nodeApi.util.upsertQueryData('getNodeDetails', { slug: node.slug }, node)))

            if (data.length === 0) {
              dispatch(setUserHasReachedEnd({ username, type: 'following', hasReachedEnd: true }))
            }
          }
        } catch (_err) {
          /* Do nothing */
        }
      },
      transformResponse: (response: RecommendResponse) => {
        return filterResourcesByTypes(response.items, ['user', 'node'])
      },
    }),
    getUserFollowers: builder.query<IntoUser[] | undefined, UserFollowingApiRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: args => {
        const { userId, cookie, page } = args
        let config = IntoAPI.user.getLikers({ page, user_id: userId, keyId: 'getUserLikes' })

        if (cookie) {
          config = { ...config, headers: { ...config.headers, cookie } }
        }
        return {
          config,
        }
      },
      merge: (existing, incoming) => {
        return [...(existing ? existing : []), ...(incoming ? incoming : [])]
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page && (currentArg?.page ?? 0) > (previousArg?.page ?? 0)
      },
      providesTags: (_result, _error, { username }) => {
        return [{ type: 'User', id: `${username}_followers` }]
      },
      serializeQueryArgs: ({ queryArgs: { username }, endpointName }) => {
        return `${endpointName}({"username":"${username}"})}`
      },
      onQueryStarted: async ({ username }, { dispatch, queryFulfilled }) => {
        try {
          const { data: users } = await queryFulfilled
          if (users) {
            users.forEach(user =>
              dispatch(userApi.util.upsertQueryData('getUserDetails', { userId: user.user_id }, user))
            )

            if (users.length === 0) {
              dispatch(setUserHasReachedEnd({ username, type: 'followers', hasReachedEnd: true }))
            }
          }
        } catch (_err) {
          /* Do nothing */
        }
      },
      transformResponse: (response: RecommendResponse) => {
        return filterResourcesByType(response.items, 'user')
      },
    }),
  }),
})

export default userApi
