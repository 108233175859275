import Image from 'next/image'
import { useCallback, useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import useHasMounted from '@hooks/useHasMounted'
import useIntersectionObserver from '@hooks/useInteractionObserver'
import IconMixLogo from '@icons/IconMixLogo.svg'
import UrlHelper from '@lib/UrlHelper'
import { IntoUser } from '@models/IntoUser'
import { CommonNotification } from '@models/NotificationsResponse'
import notificationsApi from '@redux/api/notificationsApi'
import { incrementNotificationsPage } from '@redux/slices/notificationsSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import { Notification, NotificationImage, NotificationStyles } from './Notifications'

export const NotificationsList = ({ styles }: { styles?: NotificationStyles }) => {
  const dispatch = useAppDispatch()
  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  const { page, hasReachedEnd } = useAppSelector(state => state.notifications)
  const { data, isLoading, isFetching, isUninitialized } = notificationsApi.useGetNotificationsQuery(
    { page },
    { skip: !inView }
  )

  const { currentUser } = useCurrentUserContext()
  const hasMounted = useHasMounted()

  const pinnedNotification = useMemo<CommonNotification | null>(() => {
    if (!hasMounted) return null
    if (!currentUser?.meta?.requireOnboarding) return null
    return {
      id: 'onboarding-notification',
      type: 'onboarding',
      status: 'unread',
      notification_at: new Date().toISOString(),
      title: 'Select your favorite topics to personalize your feed!',
      content: { user: currentUser as IntoUser },
    }
  }, [hasMounted, currentUser])

  // Combine pinned item with server notifications
  const combinedNotifications = pinnedNotification ? [pinnedNotification, ...(data?.items ?? [])] : (data?.items ?? [])

  const loadMore = useCallback(() => {
    if (hasReachedEnd || isLoading || isFetching || isUninitialized) return
    dispatch(incrementNotificationsPage())
  }, [dispatch, hasReachedEnd, isLoading, isFetching, isUninitialized])

  const sentinelRef = useRef<HTMLDivElement>(null)
  useIntersectionObserver({ sentinelRef, loadMore, shouldLoadMore: !isLoading, page })

  // Extract styles with default values
  const {
    readBackgroundColor = 'light:bg-menu dark:bg-contrast/[.16]',
    hoverBackgroundColor = 'hover:bg-primary/80',
    hoverLightBackgroundColor = 'light:hover:bg-contrast/[.03]',
    hoverDarkBackgroundColor = 'dark:hover:bg-contrast/20',
  } = styles || {}

  return (
    <div ref={ref} className="relative overflow-hidden bg-primary light:bg-menu">
      {!isLoading && combinedNotifications.length === 0 && (
        <div className="flex h-24 w-full items-center justify-center overflow-x-hidden text-sm font-light light:bg-menu dark:bg-contrast/[.16]">
          <div>No Notifications Yet</div>
        </div>
      )}
      <div className="scrollbar-menu size-full overflow-x-hidden">
        <div className="relative flex flex-col text-lg outline-none">
          {combinedNotifications.map(notification => {
            switch (notification.type) {
              case 'marketing':
                return (
                  <Notification
                    key={notification.id}
                    href={notification.content.destination_url}
                    notification={notification}
                    styles={{
                      ...styles,
                      unreadBackgroundColor: styles?.unreadBackgroundColor || 'bg-[#FF8004]/30',
                    }}
                    hideTimestamp={notification.content.hide_timestamp}
                    badge={<Image src={notification.content.badge_image_url} alt="" height={40} width={40} />}
                  >
                    <NotificationImage
                      src={notification.content.image_url}
                      alt={notification.title}
                      shape="square"
                      secondaryImage={{
                        src: notification.content.secondary_image_url,
                        alt: '',
                        hideBorder: true,
                      }}
                    />
                    <div className="leading-tight">
                      <div className="text-sm font-medium">{notification.title}</div>
                      <div className="text-xs text-[#C6C6C6]">{notification.content.description}</div>
                    </div>
                  </Notification>
                )
              case 'user_follow':
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    href={UrlHelper.userPath(notification.content.follower.username)}
                    styles={styles}
                  >
                    <NotificationImage
                      src={notification.content?.follower?.photo_url ?? '/avatar-dark.svg'}
                      alt={notification.content?.follower?.username}
                      shape="round"
                    />
                    <span className="text-sm leading-tight">{notification.title}</span>
                  </Notification>
                )
              case 'share':
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    styles={styles}
                  >
                    <NotificationImage
                      src={notification.content.url?.thumbnail ?? ''}
                      alt={notification.content.url.title}
                      shape="square"
                      secondaryImage={{
                        src: notification.content.sender?.photo_url ?? '/avatar-dark.svg',
                        alt: notification.content.sender?.username ?? '',
                      }}
                    />
                    <div className="flex flex-col space-y-1">
                      <span className="whitespace-nowrap text-sm leading-tight">{notification.title}</span>
                      <span className="text-xs font-light leading-tight">{notification.content.caption}</span>
                    </div>
                  </Notification>
                )
              case 'share_comment':
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    styles={styles}
                  >
                    <NotificationImage
                      src={notification.content.comment_creator?.photo_url ?? '/avatar-dark.svg'}
                      alt={notification.content.comment_creator?.username ?? ''}
                      shape="round"
                      secondaryImage={{
                        src: notification.content.url?.thumbnail ?? '',
                        alt: notification.content.url.title,
                      }}
                    />
                    <span className="text-sm leading-tight">{notification.title}</span>
                  </Notification>
                )
              case 'share_reaction':
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    styles={styles}
                  >
                    <NotificationImage
                      src={notification.content.reaction_creator?.photo_url ?? '/avatar-dark.svg'}
                      alt={notification.content.reaction_creator?.username ?? ''}
                      shape="round"
                      secondaryImage={{
                        src: notification.content.url?.thumbnail ?? '',
                        alt: notification.content.url.title,
                      }}
                    />
                    <span className="text-sm leading-tight">{notification.title}</span>
                  </Notification>
                )
              case 'comment_mention':
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    styles={styles}
                  >
                    <NotificationImage
                      src={notification.content.comment_creator?.photo_url ?? '/avatar-dark.svg'}
                      alt={notification.content.comment_creator?.username ?? ''}
                      shape="round"
                      secondaryImage={{
                        src: notification.content.url?.thumbnail ?? '',
                        alt: notification.content.url.title,
                      }}
                    />
                    <span className="text-sm leading-tight">{notification.title}</span>
                  </Notification>
                )
              case 'comment_reply':
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    styles={styles}
                  >
                    <NotificationImage
                      src={notification.content.comment_creator?.photo_url ?? '/avatar-dark.svg'}
                      alt={notification.content.comment_creator?.username ?? ''}
                      shape="round"
                      secondaryImage={{
                        src: notification.content.url?.thumbnail ?? '',
                        alt: notification.content.url.title,
                      }}
                    />
                    <div className="flex flex-col space-y-1">
                      <span className="whitespace-nowrap text-sm leading-tight">{notification.title}</span>
                      <span className="text-xs font-light leading-tight">{notification.content.text}</span>
                    </div>
                  </Notification>
                )
              case 'url_comment_reaction':
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    styles={styles}
                  >
                    <NotificationImage
                      src={notification.content.reaction_creators[0]?.photo_url ?? '/avatar-dark.svg'}
                      alt={notification.content.reaction_creators[0]?.username ?? ''}
                      shape="round"
                      secondaryImage={{
                        src: notification.content.url?.thumbnail ?? '',
                        alt: notification.content.url.title,
                      }}
                    />
                    <div className="flex flex-col space-y-1">
                      <span className="whitespace-nowrap text-sm leading-tight">{notification.title}</span>
                    </div>
                  </Notification>
                )
              case 'onboarding':
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    href="/setup/onboarding"
                    styles={styles}
                  >
                    <div className="flex size-10 shrink-0 items-center justify-center">
                      <IconMixLogo className="size-8" />
                    </div>
                    <span className="text-sm leading-tight">{notification.title}</span>
                  </Notification>
                )
              default:
                return null
            }
          })}
          {!hasReachedEnd && (
            <div
              ref={sentinelRef}
              className={`${readBackgroundColor} flex items-center justify-between space-x-4 p-4 text-left ${hoverBackgroundColor} ${hoverLightBackgroundColor} ${hoverDarkBackgroundColor}`}
            >
              <div className="flex animate-pulse items-center space-x-4">
                <span className="inline-block aspect-square w-10 shrink-0 rounded-full bg-contrast/10"></span>
                <span className="block h-4 w-[150px] rounded bg-contrast/10" />
              </div>
              <span className="block h-4 w-[40px] rounded bg-contrast/10" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
