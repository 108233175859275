const RabbitHoleCopy = {
  heading: 'A new kind of internet rabbit hole',
  subheading: 'One that makes you feel good — without any of the noise, ads, or privacy concerns.',
}

const RabbitHoleElement = () => (
  <>
    <h1 className="mb-8 font-sans text-5xl font-bold tracking-tight md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
      A new kind of
      <br />
      internet rabbit hole
    </h1>
    <h2 className="mb-10 text-lg leading-tight opacity-50 lg:text-xl 2xl:text-2xl">
      One that makes you feel good — without any of the noise, ads, or privacy concerns.
    </h2>
  </>
)

const RabbitHole = { element: RabbitHoleElement, copy: RabbitHoleCopy }

export default RabbitHole
