import { useEffect } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useGlobalContext } from '@context/GlobalContexts'
import { useRecordSession } from '@hooks/analytics/useRecordSession'
import runtimeConfig from '@lib/RuntimeConfig'

const LOGGED_OUT_SESSION_RECORDING_RATE = 0.1

export const SessionRecordingObserver = () => {
  const { isLoggedIn } = useCurrentUserContext()
  const { startSampledRecording } = useRecordSession()
  const { isEnabled } = runtimeConfig().publicRuntimeConfig.analytics.statsig
  const { browserInfo } = useGlobalContext()

  useEffect(() => {
    if (!isEnabled || browserInfo.browser.bot) return
    // Record 10% of logged out sessions
    if (!isLoggedIn) startSampledRecording(LOGGED_OUT_SESSION_RECORDING_RATE)
  }, [isLoggedIn, startSampledRecording, isEnabled, browserInfo.browser.bot])

  return null
}
