const BestOfCopy = {
  heading: 'The internet, best of edition',
  subheading: 'Pulling from all the social networks, only showing you the best bits.',
}

const BestOfElement = () => (
  <>
    <h1 className="mb-8 font-sans text-5xl font-bold tracking-tight md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
      The internet,
      <br />
      best of edition
    </h1>
    <h2 className="mb-10 text-lg leading-tight opacity-50 lg:text-xl 2xl:text-2xl">
      Pulling from all the social networks,
      <br />
      only showing you the best bits.
    </h2>
  </>
)

const BestOf = { element: BestOfElement, copy: BestOfCopy }

export default BestOf
