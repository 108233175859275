import { useEffect, useRef } from 'react'

import { AuthLink } from '@desktop/login/AuthLink'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import IconApple from '@icons/IconApple.svg'
import IconFacebook from '@icons/IconAuthFacebook.svg'
import IconTwitter from '@icons/IconAuthTwitter.svg'
import IconEmail from '@icons/IconEmail.svg'
import IconSpinner from '@icons/IconSpinner.svg'
import { setIsFiltersEnabled, setSharingEnabled, setUrlLikesEnabled } from '@redux/slices/headerSlice'
import { setIsLoginModalOpen, setLoginModalInnerUI } from '@redux/slices/modals/loginModalSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import classes from '@styles/Home.module.css'
import { curatorCount } from '../utils'
import Taglines from './tagline'

interface LoggedOutHeroProps {
  onContinueClick: () => void
  promptText: string
  isMuted?: boolean
}

export const LoggedOutHero = ({ promptText, isMuted = true, onContinueClick }: LoggedOutHeroProps) => {
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const { element: TaglineElement } = Taglines[currentPath as keyof typeof Taglines] ?? Taglines.default
  const dispatch = useAppDispatch()
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const { isLoginPromptOpen } = useAppSelector(state => state.loginModal)
  const trackClick = useTrackClick()

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted
      // If video is not muted, reset it to the start so we don't skip the first bang
      if (!isMuted) {
        videoRef.current.currentTime = 0
      }
      videoRef.current.play().catch(() => {})
    }
  }, [isMuted])

  useEffect(() => {
    if (isLoginPromptOpen) {
      dispatch(setUrlLikesEnabled(false))
      dispatch(setSharingEnabled(false))
      dispatch(setIsFiltersEnabled(false))
    } else {
      dispatch(setUrlLikesEnabled(true))
      dispatch(setSharingEnabled(true))
      dispatch(setIsFiltersEnabled(true))
    }
  }, [isLoginPromptOpen, dispatch])

  return (
    <div className="relative size-full">
      <div className="absolute inset-0 flex items-center justify-center overflow-hidden transition-all">
        <video
          ref={videoRef}
          muted
          autoPlay
          loop
          playsInline
          className="size-full object-cover"
          poster="https://assets.mix.com/static/onboarding/intro_desktop_fullscreen.jpg"
        >
          <source src="https://assets.mix.com/static/onboarding/intro_desktop_fullscreen.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="relative z-10 flex h-full flex-col bg-black/70 text-center">
        <div
          className={`flex h-full flex-col justify-center space-y-10 transition-opacity ${classes.animateFlyLongReverse}`}
        >
          <div>
            <TaglineElement />
          </div>
          <div className="mb-8 flex flex-col items-center space-y-4">
            <div className="font-semibold text-white/80">
              Join <span className="proportional-nums">{curatorCount()}</span> Curators today
            </div>
            <div
              className="g_id_signin animate-breathing overflow-hidden rounded-full bg-white"
              style={{
                height: '40px',
                ['--breathing-scale-min' as string]: '1.2',
                ['--breathing-scale-max' as string]: '1.4',
              }}
              data-type="standard"
              data-size="large"
              data-theme="outline"
              data-text="continue_with"
              data-shape="pill"
              data-logo_alignment="left"
            />
            <div className="text-sm font-medium text-white/70">or continue with...</div>
            <div className="flex space-x-2">
              <AuthLink
                name="Facebook"
                buttonClass="bg-white/10 size-13 text-white font-medium text-base"
                parentSource="logged-out-home"
              >
                {isSubmitting => (
                  <>
                    {isSubmitting ? (
                      <IconSpinner className="h-6" />
                    ) : (
                      <span className="w-6">
                        <IconFacebook />
                      </span>
                    )}
                  </>
                )}
              </AuthLink>
              <AuthLink
                name="Twitter"
                buttonClass="bg-white/10 size-13 text-white font-medium text-base"
                parentSource="logged-out-home"
              >
                {isSubmitting => (
                  <>
                    {isSubmitting ? (
                      <IconSpinner className="h-6" />
                    ) : (
                      <span className="w-6">
                        <IconTwitter />
                      </span>
                    )}
                  </>
                )}
              </AuthLink>
              <AuthLink
                name="Apple"
                buttonClass="bg-white/10 size-13 text-white font-medium text-base"
                parentSource="logged-out-home"
              >
                {isSubmitting => (
                  <>
                    {isSubmitting ? (
                      <IconSpinner className="h-6" />
                    ) : (
                      <span className="w-6">
                        <IconApple />
                      </span>
                    )}
                  </>
                )}
              </AuthLink>
              <button
                onClick={() => {
                  trackClick('emailAuth', { source: 'logged-out-home' })
                  dispatch(setLoginModalInnerUI('email'))
                  dispatch(setIsLoginModalOpen(true))
                }}
                className="btn btn-square size-13 flex cursor-pointer items-center space-x-4 bg-white/10 p-4 text-base font-medium text-white"
              >
                <span className="w-6">
                  <IconEmail />
                </span>
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={onContinueClick}
          className="mx-auto flex flex-col items-center gap-2 p-6 text-sm font-medium text-white"
        >
          <span>{promptText}</span>
          <svg
            className="animate-bounce"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 10L12.3137 15.3137L17.6274 10"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}
