const SurprisinglyPersonalCopy = {
  heading: 'Surprisingly personal',
  subheading: 'Mix learns what you like, and finds just the right thing for you.',
}

const SurprisinglyPersonalElement = () => (
  <>
    <h1 className="mb-8 font-sans text-6xl font-bold tracking-tight md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
      Surprisingly
      <br />
      personal
    </h1>
    <h2 className="mb-10 text-lg leading-tight opacity-50 lg:text-xl 2xl:text-2xl">
      Mix learns what you like,
      <br />
      and finds just the right thing for you.
    </h2>
  </>
)

const SurprisinglyPersonal = { element: SurprisinglyPersonalElement, copy: SurprisinglyPersonalCopy }

export default SurprisinglyPersonal
