import { HYDRATE } from 'next-redux-wrapper'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../../store/store'

const OTP_LENGTH = 6

export interface ActivateModalSlice {
  isActivateModalOpen: boolean
  url: string | undefined | null
  pin: string
  isSubmitting: boolean
  isSkipPinEntry: boolean
  deviceName: string
  _metadata: { updatedFields: string[] }
}

const initialState: ActivateModalSlice = {
  isActivateModalOpen: false,
  url: null,
  pin: '',
  isSubmitting: false,
  isSkipPinEntry: false,
  deviceName: 'your device',
  _metadata: { updatedFields: [] },
}

const activateModalSlice = createSlice({
  name: 'activateModal',
  initialState,
  reducers: {
    setIsActivateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isActivateModalOpen = action.payload
      state._metadata.updatedFields.push('isActivateModalOpen')
    },
    setUrl: (state, action: PayloadAction<string | undefined | null>) => {
      state.url = action.payload
      state._metadata.updatedFields.push('url')
    },
    setPin: (state, action: PayloadAction<string>) => {
      state.pin = action.payload
      state._metadata.updatedFields.push('pin')
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload
      state._metadata.updatedFields.push('isSubmitting')
    },
    setIsSkipPinEntry: (state, action: PayloadAction<boolean>) => {
      state.isSkipPinEntry = action.payload
      state._metadata.updatedFields.push('isSkipPinEntry')
    },
    setDeviceName: (state, action: PayloadAction<string>) => {
      state.deviceName = action.payload
      state._metadata.updatedFields.push('deviceName')
    },
    setModalStateBasedOnPath: (state, action: PayloadAction<string>) => {
      const path = action.payload
      state.isActivateModalOpen = path.startsWith('/activate')
      state.url = state.isActivateModalOpen ? path.split('/activate?url=')[1] : null
      state._metadata.updatedFields.push('isActivateModalOpen')
      state._metadata.updatedFields.push('url')
    },
    initializeFromSearchParams: (state, action: PayloadAction<{ pin?: string | null; deviceName?: string | null }>) => {
      const { pin, deviceName } = action.payload
      const cleanedPin =
        pin
          ?.toUpperCase()
          .replace(/[^A-Z0-9]/g, '')
          .substring(0, OTP_LENGTH) ?? ''
      state.pin = cleanedPin
      state.isSkipPinEntry = cleanedPin.length === OTP_LENGTH
      state.deviceName = deviceName || 'your device'
      state._metadata.updatedFields.push('pin', 'isSkipPinEntry', 'deviceName')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.activateModal

        const fieldsToCheck: (keyof ActivateModalSlice)[] = [
          'isActivateModalOpen',
          'url',
          'pin',
          'isSubmitting',
          'isSkipPinEntry',
          'deviceName',
        ]

        function updateField<K extends keyof ActivateModalSlice>(
          state: ActivateModalSlice,
          field: K,
          value: ActivateModalSlice[K]
        ): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (
            serverApp._metadata.updatedFields.includes(field) &&
            serverApp[field] !== undefined &&
            serverApp[field] !== state[field]
          ) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const {
  setIsActivateModalOpen,
  setUrl,
  setPin,
  setIsSubmitting,
  setIsSkipPinEntry,
  setDeviceName,
  setModalStateBasedOnPath,
  initializeFromSearchParams,
} = activateModalSlice.actions

export default activateModalSlice.reducer
