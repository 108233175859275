const GemsCopy = {
  heading: 'Find the gems meant for you',
  subheading: 'Explore a Mix of the most interesting things people think you should see.',
}

const GemsElement = () => (
  <>
    <h1 className="mb-8 font-sans text-5xl font-bold tracking-tight md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
      Find the gems
      <br />
      meant for you
    </h1>
    <h2 className="mb-10 text-lg leading-tight opacity-50 lg:text-xl 2xl:text-2xl">
      Explore a Mix of the most interesting things people think you should see.
    </h2>
  </>
)

const Gems = { element: GemsElement, copy: GemsCopy }

export default Gems
