import React from 'react'

import { Switch } from '@headlessui/react'

interface ToggleSwitchProps {
  isEnabled: boolean
  onToggle: (isChecked: boolean) => void
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isEnabled = false, onToggle }) => {
  return (
    <Switch
      checked={isEnabled}
      onChange={onToggle}
      className={`${
        isEnabled ? 'bg-accent' : 'bg-gray-200 light:bg-gray-300'
      } relative inline-flex h-6 w-11 cursor-pointer items-center rounded-full`}
    >
      <span
        className={`${isEnabled ? 'translate-x-6' : 'translate-x-1'} inline-block size-4 rounded-full bg-white transition`}
      />
    </Switch>
  )
}

export default ToggleSwitch
