import { useEffect, useRef, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useRequest } from '@hooks/useRequest'
import { selectIntoConfig, setIntoConfig } from '@redux/slices/appSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import IntoAPI, { IntoConfig } from '@services/IntoAPI'

export const useIntoConfig = (): IntoConfig | null => {
  const { isLoggedIn } = useCurrentUserContext()
  const [localStorageConfig, setLocalStorageConfig] = useLocalStorage<IntoConfig | null>('intoConfig', null)
  const [isMounted, setIsMounted] = useState(false)
  const dispatch = useAppDispatch()
  const reduxIntoConfig = useAppSelector(selectIntoConfig)
  const hasInitializedRef = useRef(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const { data: apiConfig } = useRequest(isLoggedIn && isMounted ? IntoAPI.getIntoConfig() : null, {
    fallbackData: localStorageConfig,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
    revalidateOnFocus: true,
    revalidateIfStale: true,
  })

  useEffect(() => {
    if (apiConfig && JSON.stringify(apiConfig) !== JSON.stringify(localStorageConfig)) {
      setLocalStorageConfig(apiConfig)
    }
  }, [apiConfig, localStorageConfig, setLocalStorageConfig])

  useEffect(() => {
    if (apiConfig && JSON.stringify(apiConfig) !== JSON.stringify(reduxIntoConfig)) {
      dispatch(setIntoConfig(apiConfig))
    }
  }, [apiConfig, reduxIntoConfig, dispatch])

  useEffect(() => {
    if (!hasInitializedRef.current && !reduxIntoConfig && localStorageConfig) {
      dispatch(setIntoConfig(localStorageConfig))
      hasInitializedRef.current = true
    }
  }, [reduxIntoConfig, localStorageConfig, dispatch])

  return isMounted ? reduxIntoConfig || apiConfig || localStorageConfig : null
}

/**
 * Component to load IntoConfig into Redux store
 * Use this in a parent component that has access to AppProviders context
 */
export const IntoConfigLoader = () => {
  // This will load the intoConfig into the Redux store
  useIntoConfig()
  return null
}
