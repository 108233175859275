'use client'

import type React from 'react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

interface PinInputProps {
  length?: number
  onComplete?: (pin: string) => void
  disabled?: boolean
  initialPin?: string
  isVisible?: boolean
  onSubmit?: () => void
}

const PIN_LENGTH = 6

const PinInput: React.FC<PinInputProps> = ({
  length = PIN_LENGTH,
  onComplete,
  disabled,
  initialPin = '',
  isVisible = true,
  onSubmit,
}) => {
  const [pin, setPin] = useState<string[]>(() => {
    const initialPinArray = initialPin.slice(0, length).split('')
    return Array(length)
      .fill('')
      .map((_, index) => initialPinArray[index] || '')
  })
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])
  const hasInitialFocusRef = useRef(false)

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, length)
  }, [length])

  useLayoutEffect(() => {
    if (!isVisible || hasInitialFocusRef.current) return

    // Focus the first empty field or the last field if all are filled
    const firstEmptyIndex = pin.findIndex(char => char === '')
    const focusIndex = firstEmptyIndex === -1 ? length - 1 : firstEmptyIndex

    // Use requestAnimationFrame to ensure the DOM is ready
    requestAnimationFrame(() => {
      inputRefs.current[focusIndex]?.focus()
      hasInitialFocusRef.current = true
    })
  }, [isVisible, pin, length])

  // Reset the focus flag when the modal closes
  useEffect(() => {
    if (!isVisible) {
      hasInitialFocusRef.current = false
    }
  }, [isVisible])

  const handleChange = (index: number, value: string) => {
    if (disabled) return
    value = value.toUpperCase()
    if (!/^[A-Z0-9]{0,1}$/.test(value)) return

    const newPin = [...pin]
    newPin[index] = value.slice(-1)
    setPin(newPin)

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus()
    }

    if (onComplete) {
      if (newPin.every(char => char !== '')) {
        onComplete(newPin.join(''))
      } else {
        onComplete('')
      }
    }
  }

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return
    if (e.key === 'Backspace' && !pin[index] && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
    if (e.key === 'Enter' && pin.every(char => char !== '') && onSubmit) {
      onSubmit()
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (disabled) return
    e.preventDefault()
    const pastedData = e.clipboardData.getData('text/plain').slice(0, length).split('')
    const newPin = [...pin]
    pastedData.forEach((value, index) => {
      if (index < length && /^\d$/.test(value)) {
        newPin[index] = value
      }
    })
    setPin(newPin)
    const focusIndex = Math.min(length - 1, pastedData.length)
    inputRefs.current[focusIndex]?.focus()
  }

  return (
    <div className="flex w-full items-center justify-center gap-2">
      {pin.map((char, index) => (
        <input
          key={index}
          ref={el => {
            inputRefs.current[index] = el
          }}
          type="text"
          inputMode="text"
          pattern="[A-Za-z0-9]{1}"
          maxLength={1}
          value={char}
          disabled={disabled}
          onChange={e => handleChange(index, e.target.value)}
          onKeyDown={e => handleKeyDown(index, e)}
          onPaste={handlePaste}
          className="h-14 w-full rounded-lg bg-[#232323] text-center font-mono text-xl font-semibold text-accent disabled:cursor-not-allowed disabled:opacity-50"
          aria-label={`PIN ${index + 1}`}
        />
      ))}
    </div>
  )
}

export default PinInput
