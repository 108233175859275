import { useRouter } from 'next/router'
import React, { ReactElement } from 'react'

import { UserProfileMetaTags } from '@pages/user/components/UserProfileMetaTags'
import UserSidePanel from '@pages/user/components/UserSidePanel'
import userApi from '@redux/api/userApi'

interface UserProfileLayoutProps {
  children: JSX.Element
  username: string
  className?: string
}

const UserProfileLayoutInner = ({ children, username, className }: UserProfileLayoutProps) => {
  const { data: user } = userApi.useGetUserDetailsQuery({ username })
  const router = useRouter()
  const isBlockedPage = router.pathname.includes('/blocked')

  return (
    <>
      {user && <UserProfileMetaTags user={user} />}
      <div
        className={`flex size-full flex-col overflow-y-scroll scrollbar-hide md:flex-row ${className}`}
        id="scrolling-grid"
      >
        <div className="md:scrollbar-primary shrink-0 p-6 pt-0 md:sticky md:top-0 md:w-96 md:overflow-y-scroll md:pt-6">
          <UserSidePanel userId={user?.user_id} />
        </div>
        <div className="relative size-full">
          <div className={`relative ${isBlockedPage ? 'p-0' : 'p-1'}`} style={{ overflow: 'unset' }}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

const UserProfileLayout = ({ children, className }: { children: ReactElement; className?: string }) => {
  const router = useRouter()
  const isUserPage = router.route.startsWith('/user/')
  const username = Array.isArray(router.query.username) ? router.query.username[0] : router.query.username

  if (!isUserPage || !username) return children
  return (
    <UserProfileLayoutInner username={username} className={className}>
      {children}
    </UserProfileLayoutInner>
  )
}

export default UserProfileLayout
