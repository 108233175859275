import axios, { AxiosError } from 'axios'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { useCallback, useEffect, useRef } from 'react'
import toast from 'react-hot-toast'

import Modal from '@common/Modal'
import PinInput from '@common/PinInput'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { MetaTags } from '@desktop/MetaTags'
import { errorToHelpfulMessage } from '@models/APIErrorResponse'
import { setChromeState } from '@redux/slices/mobileSlice'
import {
  initializeFromSearchParams,
  setIsActivateModalOpen,
  setIsSkipPinEntry,
  setIsSubmitting,
  setPin,
} from '@redux/slices/modals/activateModalSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import MixMix from '@services/MixMix'

const OTP_LENGTH = 6

export const useDeviceConnection = () => {
  const dispatch = useAppDispatch()
  const { pin, isSubmitting } = useAppSelector(state => state.activateModal)

  const handleConnect = useCallback(async () => {
    if (!pin) return

    dispatch(setIsSubmitting(true))
    try {
      const { data } = await axios.request(MixMix.pin.connect({ pin }))

      if (data.ok) {
        void dispatch(setChromeState({ hideHeader: false, hideTabNav: false }))
        toast.success('Device connected successfully')
        dispatch(setIsActivateModalOpen(false))
      } else {
        throw new Error('Failed to connect device')
      }
    } catch (error) {
      const axiosError = error as AxiosError<{ message: string }>
      toast.error(errorToHelpfulMessage(axiosError) || 'Failed to connect device: Authentication failed')
    } finally {
      dispatch(setIsSubmitting(false))
    }
  }, [pin, dispatch])

  return { handleConnect, isSubmitting }
}

export default function ActivateModal() {
  const searchParams = useSearchParams()
  const dispatch = useAppDispatch()
  const { currentUser: user } = useCurrentUserContext()
  const { pin, isSubmitting, isSkipPinEntry, deviceName, isActivateModalOpen } = useAppSelector(
    state => state.activateModal
  )
  const { handleConnect } = useDeviceConnection()
  const hasInitialized = useRef(false)

  // Initialize from URL parameters only once when the modal first opens
  useEffect(() => {
    if (isActivateModalOpen && !hasInitialized.current) {
      dispatch(
        initializeFromSearchParams({
          pin: searchParams.get('pin'),
          deviceName: searchParams.get('device_name'),
        })
      )
      hasInitialized.current = true
    } else if (!isActivateModalOpen) {
      // Reset the initialization flag when modal closes
      hasInitialized.current = false
    }
  }, [isActivateModalOpen, searchParams, dispatch])

  const handleCancel = useCallback(() => {
    dispatch(setIsActivateModalOpen(false))
    dispatch(setIsSkipPinEntry(false))
    dispatch(setPin(''))
  }, [dispatch])

  return (
    <Modal isVisible={isActivateModalOpen} onClose={handleCancel}>
      <>
        <MetaTags title="Connect Device - Mix" />
        <div className="relative z-20 m-auto mx-4 flex min-h-dvh max-w-screen-sm items-center justify-center">
          <div className="flex flex-col items-center">
            {user?.photo_url && (
              <div className="relative -mb-10 size-20 overflow-hidden rounded-full bg-accent-dark">
                <Image src={user?.photo_url} alt="" width={80} height={80} className="size-full object-cover" />
              </div>
            )}

            <div className="flex w-full flex-col items-center rounded-[48px] bg-bottomPanel px-6 pb-6 pt-14 text-center text-bottomPanel">
              {isSkipPinEntry ? (
                <>
                  <div className="mb-1 text-2xl font-bold tracking-tight">{user?.display_name}</div>
                  <div className="mb-6 text-base tracking-normal text-stone-500">@{user?.username}</div>
                </>
              ) : null}

              <div className="mb-2 text-2xl font-medium tracking-tight">Connect with device</div>
              <div className="mb-8 text-base leading-5 tracking-normal text-bottomPanel">
                {isSkipPinEntry
                  ? `Would you like to connect your account with ${deviceName}?`
                  : 'Enter the code displayed on your device'}
              </div>

              {!isSkipPinEntry && (
                <div className="mb-10 px-4">
                  <PinInput
                    length={OTP_LENGTH}
                    onComplete={value => dispatch(setPin(value))}
                    disabled={isSubmitting}
                    initialPin={pin}
                    isVisible={isActivateModalOpen}
                    onSubmit={handleConnect}
                  />
                </div>
              )}

              <button
                onClick={handleConnect}
                disabled={isSubmitting || !pin || pin.length !== OTP_LENGTH}
                className="btn btn-primary mb-2 w-full py-4 font-medium"
              >
                Connect
              </button>
              <button onClick={handleCancel} className="btn w-full bg-transparent py-4 font-normal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </>
    </Modal>
  )
}
