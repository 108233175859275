import './MixAxios'

import { AxiosHeaders, AxiosRequestConfig } from 'axios'

import { APIRequest } from '@hooks/useRequest'
import { commands } from '@lib/messages/protobuf'
import runtimeConfig from '@lib/RuntimeConfig'
import { ExtractMetaResponse } from '@models/ExtractResponse'
import { IntoUrl } from '@models/IntoUrl'
import { IntoUser } from '@models/IntoUser'
import { UrlCommentsResponse } from '@models/UrlCommentsResponse'
import { NotificationSettings } from '@pages/settings/components/notificationTypes'

import CommandContext = commands.CommandContext
import ITrackContentDwell = commands.ITrackContentDwell
import ITrackContentPause = commands.ITrackContentPause
import ITrackContentPlay = commands.ITrackContentPlay
import ITrackContentTimePlayed = commands.ITrackContentTimePlayed
import ITrackContentSelection = commands.ITrackContentSelection
import ITrackContentView = commands.ITrackContentView
import ITrackGridViews = commands.ITrackGridViews

import RateUrl = commands.RateUrl
import TrackExternalUrlPrivateShareEvent = commands.TrackExternalUrlPrivateShareEvent
import TrackUserSession = commands.TrackUserSession

const MixMixConfig = <Request>(requestConfig: AxiosRequestConfig<Request>): AxiosRequestConfig<Request> => ({
  baseURL: `${runtimeConfig().publicRuntimeConfig.baseUrl}`,
  headers: new AxiosHeaders({
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
  ...requestConfig,
})

export type UrlModerateActions =
  | 'REINGEST' // re-ingest
  | 'NSFW' // Mark as nsfw
  | 'SPAM' // Mark as spam
  | 'UNBAN' // Unhide
  | 'REJECT_CHANGE_PROVIDER_TYPE' // Keep user submission
  | 'CHANGE_PROVIDER_TYPE_USER_CURATED' // Promote to Curated
  | 'SET_HIDDEN' // Hide
const MixMix = {
  logout: (): APIRequest =>
    MixMixConfig({
      method: 'GET',
      url: '/logout',
    }),
  url: {
    getComments: (data: { url_id: string }): APIRequest<UrlCommentsResponse> =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/url/comments',
        data,
      }),
    postComment: (data: { url_id: string; text: string; parent_id?: string }): APIRequest<UrlCommentsResponse> =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/comment',
        data,
      }),
    deleteComment: ({ commentID }: { commentID: string }): APIRequest<UrlCommentsResponse> =>
      MixMixConfig({
        method: 'DELETE',
        url: `/api/v0.1/comment/${commentID}`,
      }),
    reportComment: (data: {
      reported_id: string
      context: 'spam' | 'nsfw'
      pageContext: commands.CommandContext
    }): APIRequest<UrlCommentsResponse> =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/reports/comment',
        data: {
          reported_id: data.reported_id,
          context: data.context,
          page_context: data.pageContext,
        },
      }),
  },
  user: {
    /**
     * Get the current user
     * @param params - Optional query parameters
     * @param params.token - The token to use for the request (usually single use token for email -> ubsubscribe links)
     * @returns The current user
     */
    currentUser: (params?: { token?: string }): APIRequest<{ token: string; user: IntoUser }> =>
      MixMixConfig({
        method: 'GET',
        url: '/api/v0.1/user',
        params,
      }),
    deleteAccount: (): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/user/delete',
      }),
  },
  extract: {
    submit: (data: { url: string; label?: string }): APIRequest<IntoUrl> =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/extract?waitForScrape=true',
        data,
      }),
    getMeta: (params: { url: string }): APIRequest<ExtractMetaResponse> =>
      MixMixConfig({
        method: 'GET',
        url: '/api/extract/meta',
        params,
      }),
  },
  commands: {
    trackUserSession: (data: TrackUserSession) =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/track-user-session',
        data,
      }),
    trackSignupComplete: (context: CommandContext) =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/track/signup',
        data: {
          page_context: {},
          context: {
            app_name: context.session?.appVersion?.appName?.value,
            app_short_version: context.session?.appVersion?.buildNumber?.value,
            app_version: context.session?.appVersion?.versionNumber?.value,
          },
          device_id: context.session?.device?.deviceUuid?.value,
          session_id: context.session?.sessionId?.value,
          action: 'track',
        },
      }),
    trackContentView: (data: ITrackContentView) =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/track-content-view',
        data,
      }),
    trackGridViews: (data: ITrackGridViews) =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/track-grid-views',
        data,
      }),
    trackContentSelection: (data: ITrackContentSelection) =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/track-content-selection',
        data,
      }),
    trackContentDwell: (data: ITrackContentDwell): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/track-content-dwell',
        data,
      }),
    trackContentTimePlayed: (data: ITrackContentTimePlayed): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/track-content-time-played',
        data,
      }),
    trackContentPlay: (data: ITrackContentPlay): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/track-content-play',
        data,
      }),
    trackContentPause: (data: ITrackContentPause): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/track-content-pause',
        data,
      }),
    trackExternalUrlShare: (data: TrackExternalUrlPrivateShareEvent): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/track-external-url-private-share-event',
        data,
      }),
    rateUrl: (data: RateUrl) =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/commands/rate-url',
        data,
      }),
  },
  setup: {
    updateUser: (user: IntoUser): APIRequest =>
      MixMixConfig({
        method: 'PUT',
        url: '/api/v0.1/user',
        data: {
          user,
        },
      }),
    verifyEmail: (): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/user/verify-email',
      }),
    verifyEmailToken: (params: { verificationToken: string }): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/user/verify-email-token',
        params,
      }),
    uploadPhoto: (file: File): APIRequest => {
      const form = new FormData()
      form.append('user_photo', file)
      return MixMixConfig({
        headers: new AxiosHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        method: 'POST',
        url: '/api/v0.1/user/upload-photo',
        data: form,
      })
    },
    cancel: () =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/setup/cancel',
      }),
    updateNSFWSetting: (nsfwEnabled: boolean): APIRequest =>
      MixMixConfig({
        method: 'PUT',
        url: '/api/v0.1/user',
        data: {
          user: {
            settings: {
              nsfw_enabled: nsfwEnabled,
            },
          },
        },
      }),
    updateNotificationSettings: (notificationSettings: NotificationSettings): APIRequest =>
      MixMixConfig({
        method: 'PUT',
        url: '/api/v0.1/user',
        data: {
          user: {
            settings: {
              notifications: notificationSettings,
            },
          },
        },
      }),
  },
  share: {
    markRead: (shareID: number): APIRequest =>
      MixMixConfig({
        method: 'PUT',
        url: `/api/v0.2/share/${shareID}/read`,
      }),
    shareWith: (data: { url_id: string; destinationUserIds: number[]; caption?: string }): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: `/api/v0.1/share-with`,
        data,
      }),
  },
  pin: {
    connect: (data: { pin: string }): APIRequest =>
      MixMixConfig({
        method: 'POST',
        url: '/api/v0.1/pin/connect',
        data,
      }),
  },
  admin: {
    comments: {
      hide: (commentID: string): APIRequest =>
        MixMixConfig({
          method: 'PUT',
          url: `/api/v0.1/admin/comment/${commentID}/hide`,
        }),
    },
    user: {
      removeAccess: (userId: number): APIRequest =>
        MixMixConfig({
          method: 'DELETE',
          url: `/api/v0.1/admin/user/${userId}/access`,
        }),
      makeSpammer: (userId: number): APIRequest =>
        MixMixConfig({
          method: 'PUT',
          url: `/api/v0.1/admin/user/${userId}`,
          data: { id: userId, category: 'SPAMMER' },
        }),
    },
    url: {
      moderate: (urlId: string, action: UrlModerateActions, comments?: string): APIRequest =>
        MixMixConfig({
          method: 'POST',
          url: `/api/v0.1/admin/url/${urlId}/blacklist`,
          data: {
            requestedaction: action,
            additionalcomments: comments,
          },
        }),
      report: (urlId: string, context: 'spam' | 'dead' | 'nsfw'): APIRequest =>
        MixMixConfig({
          method: 'POST',
          url: `/api/v0.1/reports/url`,
          data: {
            url_id: urlId,
            context,
            page_context: {},
          },
        }),
    },
  },
}
export default MixMix
