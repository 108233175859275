const DailyDoseCopy = {
  heading: 'Your daily dose of "Wow!"',
  subheading: 'The best of all social networks in one place, without any of the noise, ads, or security concerns.',
}

const DailyDoseElement = () => (
  <>
    <h1 className="mb-8 font-sans text-6xl font-bold tracking-tight md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
      Your daily dose of <br /> &quot;Wow!&quot;
    </h1>
    <h2 className="mb-10 text-lg leading-tight opacity-50 lg:text-xl 2xl:text-2xl">
      The best of all social networks in one place, <br /> without any of the noise, ads, or security concerns.
    </h2>
  </>
)

const DailyDose = { element: DailyDoseElement, copy: DailyDoseCopy }

export default DailyDose
