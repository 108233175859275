import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'

import { requestInterceptor } from '@lib/commands/overrideCommandContextInterceptor'
import { setCanaryInterceptor } from '@lib/isCanary'

const RANDOM_MAX = 1000000
const RANDOM_DIGITS = 6

const generateUniqueNumber = (): string => {
  const timestamp = Date.now().toString()
  const random = Math.floor(Math.random() * RANDOM_MAX)
    .toString()
    .padStart(RANDOM_DIGITS, '0')
  return `${timestamp}${random}`
}

const traceIdInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const traceId = generateUniqueNumber()
  config.headers = config.headers || ({} as AxiosRequestHeaders)
  if (config.headers) {
    config.headers['x-datadog-trace-id'] = config.headers['x-datadog-trace-id'] || traceId
    config.headers['x-datadog-parent-id'] = config.headers['x-datadog-parent-id'] || 0
  }

  return config
}

axios.interceptors.request.use(setCanaryInterceptor)
axios.interceptors.request.use(requestInterceptor, undefined, { synchronous: true })
axios.interceptors.request.use(traceIdInterceptor)

export default axios
