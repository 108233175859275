import { RefObject, useEffect, useRef } from 'react'

interface UseIntersectionObserverProps {
  sentinelRef: RefObject<HTMLDivElement>
  shouldLoadMore: boolean
  loadMore: () => void
  page: number
}

export const useIntersectionObserver = ({
  sentinelRef,
  shouldLoadMore,
  loadMore,
  page,
}: UseIntersectionObserverProps) => {
  const observerRef = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      entries => {
        if (entries[entries?.length - 1].isIntersecting && shouldLoadMore) {
          loadMore()
        }
      },
      {
        threshold: 0.1,
      }
    )

    if (sentinelRef.current) {
      observerRef.current.observe(sentinelRef.current)
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [sentinelRef, shouldLoadMore, loadMore, page])
}

export default useIntersectionObserver
